<template>
	<div class="content">
		<headers :marage="159" :selectIndex="4">
			<!-- <Header /> -->
		</headers>
		<div class="container">
			<div class="banner"></div>
			<div>
				<div class="cloud" style="margin-top: 30px;">
					<div class="cloud-yun">
						<h2>什么是企课网城市分院</h2>
						<span>企课网城市分院是企课网在每个城市独家的销售与运营中心，企课网采用“一城一分院”的布局战略，以县级市、地级市为单位打造当地有影响力的企业服务在线教育平台，共同享受财富增值。</span>
						<img src="../../assets/img/productcenterimg/heng.png" alt="" />
					</div>
				</div>
				<div class="cloud">
					<div class="cloud-yun">
						<h2>企课网项目优势</h2>
						<span>强大的国家政策支持，4500万家企业的庞大的市场体量，1.5万亿的市场规模，产品好、模式新、易操作、可落地、轻投资、收入可观</span>
						<img src="../../assets/img/productcenterimg/heng.png" alt="" />
					</div>
				</div>
				<!-- -->
				<div>
					<div class="hexin">
						<div class="imger">
							<img src="../../assets/img/partnerimg/1.png" alt="" />
						</div>
						<b class="wenzi">强大的执行力</b>
						<div class="colorrr" style="border-bottom: 3px solid #5dc499"></div>
					</div>
					<div class="hexin">
						<div class="imger">
							<img src="../../assets/img/partnerimg/2.png" alt="" />
						</div>
						<b class="wenzi">对企业培训项目深刻理解</b>
						<div class="colorrr" style="border-bottom: 3px solid #3676bb"></div>
					</div>
					<div class="hexin">
						<div class="imger">
							<img src="../../assets/img/partnerimg/3.png" alt="" />
						</div>
						<b class="wenzi">精准的营销推广能力</b>
						<div class="colorrr" style="border-bottom: 3px solid #ed7088"></div>
					</div>
					<div class="hexin">
						<div class="imger">
							<img src="../../assets/img/partnerimg/4.png" alt="" />
						</div>
						<b class="wenzi">丰富的产品运营经验</b>
						<div class="colorrr" style="border-bottom: 3px solid #9c9eeb"></div>
					</div>
				</div>
			</div>
			<!--  -->
			
			<div class="scroll">
				<div class="cloud">
					<div class="cloud-yun">
						<h2 style="color: #fff">城市分院合伙人的主要工作内容</h2>
						<span></span>
						<img src="../../assets/img/productcenterimg/heng.png" alt="" />
					</div>
				</div>
				<div class="scroll-box">
					<div class="scroll-img">
						<div class="scroll-img-ico">
							<img src="../../assets/img/partnerimg/5.png" alt="" />
						</div>
						<div class="scroll-word">市场开发</div>
						<div class="scroll-dim">开发本地的企业客户采购企课网实效系列产品</div>
					</div>
					<div class="scroll-img">
						<div class="scroll-img-ico">
							<img src="../../assets/img/partnerimg/6.png" alt="" />
						</div>
						<div class="scroll-word">用户转化</div>
						<div class="scroll-dim">推动转化C端用户在线会员付费转化企业员工学习消费</div>
					</div>
					<div class="scroll-img">
						<div class="scroll-img-ico">
							<img src="../../assets/img/partnerimg/7.png" alt="" />
						</div>
						<div class="scroll-word">城市同学会运营</div>
						<div class="scroll-dim">本地企业家校董会俱乐部的运营和维护打造本地高端企业家社群平台</div>
					</div>
					<div class="scroll-img">
						<div class="scroll-img-ico">
							<img src="../../assets/img/partnerimg/8.png" alt="" />
						</div>
						<div class="scroll-word">售后服务跟进</div>
						<div class="scroll-dim">通过落地培训与星级服务提升企业长久合作</div>
					</div>
					<div class="scroll-img">
						<div class="scroll-img-ico">
							<img src="../../assets/img/partnerimg/5.png" alt="" />
						</div>
						<div class="scroll-word">正向品牌经营</div>
						<div class="scroll-dim">完成本地分院正向经营创造利润共同上市</div>
					</div>
				</div>
			</div>
			<!--  -->
			
			<div>
				<div class="cloud">
					<div class="cloud-yun">
						<h2>企课总部对分院4大培训赋能</h2>
						<span>企课网平台依托总部与地方城市分院联营模式，通过专业的人员培训、科学的培训赋能及有力的客户服务保证分院业务的开展</span>
						<img src="../../assets/img/productcenterimg/heng.png" alt="" />
					</div>
				</div>
				<div class="shop">
					<div class="shop-img">
						<img src="../../assets/img/partnerimg/one.png" alt="" />
					</div>
					<div class="shop-bg">
						<div class="shop-word">项目启动培训</div>
						<div class="shop-dim">
							提供3~5天线上及线下启动培训，从产品培训、销售培训、服务运营培训助力分院顺利开业
						</div>
					</div>
				</div>
				<div class="shop">
					<div class="shop-img">
						<img src="../../assets/img/partnerimg/two.png" alt="" />
					</div>
					<div class="shop-bg">
						<div class="shop-word">日常运营培训</div>
						<div class="shop-dim">
							针对日常工作、提供全面的体系化、专业化、日常资讯辅导分院快速盈利
						</div>
					</div>
				</div>
				<div class="shop">
					<div class="shop-img">
						<img src="../../assets/img/partnerimg/three.png" alt="" />
					</div>
					<div class="shop-bg">
						<div class="shop-word">名企互访培训</div>
						<div class="shop-dim">
							定期组织分院创始人走进阿里、百度、京东以及出国考察、开拓视野，拔高增长
						</div>
					</div>
				</div>
				<div class="shop">
					<div class="shop-img">
						<img src="../../assets/img/partnerimg/four.png" alt="" />
					</div>
					<div class="shop-bg">
						<div class="shop-word">总部商学中心赋能</div>
						<div class="shop-dim">
							企课内部人才培育中心定期输出中高管能力实战课，助力合作伙伴全方位成长
						</div>
					</div>
				</div>
			</div>
			<!--  -->
			<div class="swipe-tim">
				<div class="cloud">
					<div class="cloud-yun">
						<h2 style="color: #fff">企课总部对城市分院3大保障</h2>
						<!-- <span style="color: #FFFFFF;">企课网平台依托总部与地方城市分院联营模式，通过专业的人员培训、科学的培训赋能及有力的客户服务保证分院业务的开展</span> -->
						<img src="../../assets/img/productcenterimg/heng.png" alt="" />
					</div>
				</div>
				<div class="left" @click="eliteSwitch(1)">
					<img src="../../assets/img/homeimg/btn_left.png" alt="" />
				</div>
				<div class="right" @click="eliteSwitch(2)">
					<img src="../../assets/img/homeimg/btn_right.png" alt="" />
				</div>
				<div class="swipe-scroll">
					<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false"
						ref="swiper">
						<van-swipe-item>
							<div class="swipethree">
								<div>
									<img src="../../assets/img/partnerimg/9.png" class="swipeImg" />
								</div>
								<div class="swipethree1">匹配专人陪跑支持保障</div>
								<div class="swipethree2">
									第一时间为合作伙伴匹配专人从选址、招聘、文化建设、薪酬制度、销售邀约、活动策划等板块陪跑辅导，全力扶持合作伙伴培养团队，快速成长，提高业绩
								</div>
								<img src="../../assets/img/productcenterimg/heng.png"
									style="margin: 10px; height: 2px; width: 25px" />
							</div>
						</van-swipe-item>
						<van-swipe-item>
							<div class="swipethree">
								<div>
									<img src="../../assets/img/partnerimg/10.png" class="swipeImg" />
								</div>
								<div class="swipethree1">全面的培训体系保障</div>
								<div class="swipethree2">
									总部组织各种培训，把业内高效的销售及销售管理模式复制到合作伙伴团队，包括集中训练营，线上平台学习，以及线下培训等
								</div>
								<img src="../../assets/img/productcenterimg/heng.png"
									style="margin: 10px; height: 2px; width: 25px" />
							</div>
						</van-swipe-item>
						<van-swipe-item>
							<div class="swipethree">
								<div>
									<img src="../../assets/img/partnerimg/11.png" class="swipeImg" />
								</div>
								<div class="swipethree1">总部强力的服务团队保障</div>
								<div class="swipethree2">
									7*24小时客服专线，专业的服务团队即时在线答疑、远程指导、定期客户回访
								</div>
								<img src="../../assets/img/productcenterimg/heng.png"
									style="margin: 10px; height: 2px; width: 25px" />
							</div>
						</van-swipe-item>
					</van-swipe>
				</div>
			</div>
			
			<!--  -->
			<div class="tu-one">
				<div>
					<div class="cloud">
						<div class="cloud-yun">
							<h2>企课总部对分院8大落地支持</h2>
							<span>企课网平台依托总部与地方城市分院联营模式，进行陪伴式的支持，从签约、落地选址、开业筹备、人员招聘、业务培训和开发等多个方面支持分院，帮扶分院业务的开展。</span>
							<img src="../../assets/img/productcenterimg/heng.png" alt="" />
						</div>
					</div>
				</div>
				<div>
					<div class="tu">
						<img src="../../assets/img/partnerimg/tu.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from "vue";
	import headers from "../../components/header.vue";
	import {
		Swipe,
		SwipeItem
	} from "vant";

	Vue.use(Swipe);
	Vue.use(SwipeItem);
	// import Header from "../../components/header.vue";
	// import Hexin from "../../components/hexin.vue";
	export default {
		components: {
			headers,
		},
		methods: {
			eliteSwitch(type) {
				if (type === 1) {
					this.$refs.swiper.prev();
				} else {
					this.$refs.swiper.next();
				}
			},
		},
	};
</script>
<style lang="stylus" scoped>
	.swipe-tim {
		background url(../../assets/img/partnerimg/bg_two.png) 
		background-size 100% 100% 
		height 3.7rem 
		position: relative;
	}

	.swipethree {
		background-color #fff 
		width 270px 
		height 2.4rem 
		margin 0 auto 
		text-align center
		border-radius 10px 
		margin-top 20px 
		.swipeImg {
			width: 70px;
			height: 80px;
			margin-top 20px
		}

		.swipethree1 {
			color #333333 
			font-size 16px 
			font-weight 900 
			line-height 43px
		}

		.swipethree2 {
			width 2rem 
			color #666666 
			margin auto
		}
	}

	.banner {
		height 1.60rem 
		width 100% 
		background url(../../assets/img/partnerimg/banner.png) 
		background-size 100%
	}

	.cloud {
		display flex 
		justify-content center 
		text-align center 
		line-height 20px 
		.cloud-yun {
			margin-top 0.3rem 
			h2 {
				font-size: 0.19rem;
				font-family: PingFang SC;
				font-weight: 800;
				color: #212121;
				line-height: 26px;
			}

			span {
				display block 
				line-height: 18px 
				color #999 
				font-weight: 400 
				width 2.9rem 
				margin-top 0.1rem
			}

			img {
				width 0.38rem 
				height 0.03rem
			}
		}
	}

	.scroll {
		background url(../../assets/img/partnerimg/bg_one.png) 
		backgrou-size 100% 
		margin-top 20px
	}

	.scroll-box {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;
		align-items: stretch;
	}

	.scroll-img {
		width: 1.675rem;
		height: 1.7rem;
		background: #FFFFFF;
		border-radius: 10px;
		background-color #fff 
		margin 10px 0 20px 0 
		.scroll-img-ico {
			text-align center
			margin-top 20px
			img {
				height 0.6rem 
				width 0.625rem
			}
		}
	}

	.scroll-word {
		text-align center 
		line-height 28px 
		font-size 14px 
		font-weight 900
	}

	.scroll-dim {
		width: 1.45rem 
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 18px;
		text-align center 
		margin-left 10px
	}

	.shop-bg {
		height 0.85rem
	}

	.shop {
		width: 3.4rem;
		margin 15px auto 
		box-shadow: 0px 3px 29px 0px rgba(235, 229, 229, 0.55);
		border-radius: 12px;

		.shop-img {
			img {
				width: 3.4rem;
				height: 1.6rem;
				border-radius: 12px 12px 0px 0px;
			}
		}

		.shop-word {
			font-weight: 800;
			color: #212121;
			line-height: 20px;
			padding 10px 10px 0 10px 
			font-size 16px
		}

		.shop-dim {
			height: 35px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 16px;
			padding 8px
		}
	}

	.tu-one {
		background-color #fff
	}

	.tu {
		text-align center 
		img {
			margin 20px auto 
			width 3.3rem 
			height 3.3rem
		}
	}

	.hexin {
		width: 3.45rem;
		height: 0.7rem;
		background: #FFFFFF;
		border-radius: 0.04rem;
		margin 0 auto 
		margin-top 0.1rem 
		box-shadow: 2px 1px 16px 2px rgba(153, 153, 153, 0.15);
	}

	.wenzi {
		position absolute 
		font-size: 0.16rem;
		font-family: PingFang SC;
		color: #212121;
		line-height: 0.51rem;
		margin-left 15px
	}

	.colorrr {
		height 30px 
		width 40px 
		float left 
		margin-left 17px 
		margin-top 18px
	}

	.imger {
		img {
			height: 0.44rem;
			width: 0.44rem;
			float: left;
			margin-top: 12px;
			margin-left: 13px;
		}
	}

	.left {
		position: absolute;
		top: 2rem;
		left: 0.1rem;
		z-index: 6;

		img {
			width: 0.11rem;
			height: 0.17rem;
		}
	}

	.right {
		position: absolute;
		top: 2rem;
		right: 0.1rem;
		z-index: 6;

		img {
			width: 0.11rem;
			height: 0.17rem;
		}
	}
</style>
